<template>
  <v-container  fill-height fluid>
    <v-row align="center"
      justify="center">

    <v-card elevation="12">
      <v-card-title class="justify-space-between">
        {{$auth.user().name}}
        <v-btn @click="$auth.logout()">Выход</v-btn>
      </v-card-title>
      <v-card-subtitle>
        Выберите тестирование
      </v-card-subtitle>
      <v-divider/>
      <v-card-text>

      <v-simple-table>
      <tbody >
        <tr v-for="exam in list" :key="exam.id">
          <td class="pa-3">{{exam.qualification.name}}</td>
          <td class="pa-3"><v-chip v-if="!exam.exam || exam.exam.status===0">Не начат</v-chip>
          <v-chip v-else-if="exam.exam.status===1">В процессе</v-chip>
          <v-chip v-else>Завершён</v-chip></td>
          <td class="pa-3">
            <v-btn color="green" dark v-if="!exam.exam || exam.exam.status===0" :to="'/revision/exam/'+exam.id">Начать</v-btn>
            <v-btn color="green" dark v-else-if="exam.exam.status==1" :to="'/revision/exam/'+exam.id">Продолжить</v-btn>
            </td>
        </tr>
      </tbody>
      </v-simple-table>
      
      </v-card-text>
    </v-card>

  </v-row>


  </v-container>
</template>

<script>
export default {
  data: () => ({
      list:[],
      loading:true
  }),
  methods:{
    fetchData() {
      this.loading = true;
      this.$http
        .post("revision/exam/list")
        .then((response) => {
          this.list = response.data;
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted(){
      this.fetchData()
  }
}
</script>

<style>

</style>